<template>
  <div class="row">
    <div class="col-sm-12">
    <el-table
      :data="list"
      border
      element-loading-text="Loading"
      style="width: 100%"
      v-loading="loading"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
      
        <el-table-column
          fixed                       
          label="Employee Name"
          prop="id" 
      >
          <template slot-scope="scope">
              <el-tag slot="reference" size="mini">
                <a href="#" @click.prevent="viewEmployeeSheet(scope.row.employee_details.id)">{{scope.row.employee_details.first_name}} {{scope.row.employee_details.last_name}}</a></el-tag>
              
          </template>
      </el-table-column>
        <el-table-column
          fixed          
          label="Status"
          prop="id"
         width="250"
      >
          <template slot-scope="scope">
           <span ><b >Check IN : </b>{{scope.row.before_login}} , </span> 
           <span ><b class="red">Check IN : </b>{{scope.row.late_login}} , </span>
           <span ><b >Off : </b>{{scope.row.employee_off}}</span>                       
          </template>
      </el-table-column>
        <el-table-column
          fixed
          align="center"
          label="Total Hours"
          prop="id"
          width="120"
      >
          <template slot-scope="scope">
            <div v-if="scope.row.total_hours !='0'">{{scope.row.total_hours}}</div>
            <div v-else>
                <span v-if="scope.row.status =='Check IN'">
                <span v-if="scope.row.in_meridiem == 'AM'">                                
                  <span >{{timeCalculation(current_time ,scope.row.actual_time_in)}}</span>                               
                </span>
                <span v-if="scope.row.in_meridiem == 'PM'">
                  <span >{{totalCalculationPM(current_time ,scope.row.actual_time_in)}}</span>             
                </span>
                </span>
                  
            </div>
          </template>
      </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {getEmployeeAllDetails,getEmployeeList} from "@/api/employeeLogin";
import {globalEvents} from '@/utils/globalEvents';
const date = new Date();
export default {
    name: 'employee_time_sheet',
     props:{
     employeeId: {
      type: Number,
    },
    date_ranges:{
      type: Array
    }

    },
     data() {
    return {
        tittle:'Employee Time Sheet',
        multipleSelection: [],
        pagination: {
        total_rows: 1,
        current_page: 1,
        last_page: null,
        per_page: 5,
      },
      list : [],
       dateTime: {
        hours: date.getHours(),
        minutes: date.getMinutes(),
        seconds: date.getSeconds(),
       
      },
      current_time : 0,
      timer:undefined,
      employee_list:[],
      employee_id:0,
      date_range:[]
    }
   },
   mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Employees", route: "/employee/list" },
      { title: "Employee Time Sheet" }
    ]);
     globalEvents.$on('changeTable', chnageValue => {
       this.date_range = chnageValue;
         this.fetchData(1);
            if (chnageValue) {
                this.fetchData(1);
            }
        }); 
  },
   created() {
     this.getEmployeeList();
     this.date_range = [];
      if(this.date_ranges){      
       this.date_range = this.date_ranges
     }  
     this.employee_id = this.employeeId;
    this.fetchData(1);
    const date = new Date();
    let hours = date.getHours();
    let minutes = ('0'+date.getMinutes()).slice(-2);
    //let seconds = ('0'+date.getSeconds()).slice(-2);
   // let hour = hours > 12 ? hours - 12 : hours
   if(hours == 0){
     hours = 12
   }
    this.current_time = hours+' : '+ minutes
   
   },
  methods: {
    getEmployeeDetails(){
        this.fetchData(1);
    },
    dateClear(){
      
        this.date_range = [];
          this.fetchData(1);
    },
    handleSizeChange(per_page) {
      this.pagination.per_page = per_page;
      this.fetchData(1);
    },
   timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return  rhours + ' : ' + rminutes ;
      },
   timeCalculation(time1, time2){ 
        var Time1 = time1
        var Time2 = time2
        var splitTime1= Time1.split(':');
         var splitTime2= Time2.split(':');
        var hour=0;
        var ct_hour = (parseInt(splitTime1[0]))*60+(parseInt(splitTime1[1]))        
        var record_in = (parseInt(splitTime2[0]))*60+(parseInt(splitTime2[1]))
        
        var h = 0;
        if(ct_hour > record_in){
           h = ct_hour - record_in
        }else{
          h = 0
        }
        if(h>=60){  
          hour = this.timeConvert(h);
        }else{
          hour = '0 : '+h
        }       
        if(h > 270){
              var total = h - 30
              hour = this.timeConvert(total);
            }
          return hour;         
      },   
      totalCalculationPM(time1, time2){
       
        var splitTime1= time1.split(':');
        var splitTime2= time2.split(':');
        var hour=0;

        var ct_hour = (parseInt(splitTime1[0])-12)*60+(parseInt(splitTime1[1]))        
        var record_in = (parseInt(splitTime2[0]))*60+(parseInt(splitTime2[1]))
        
        var h = 0;
        if(ct_hour > record_in){
           h = ct_hour - record_in
        }else{
          h = 0
        }      
       
        if(h>=60){  
          hour = this.timeConvert(h);
        }else{
          hour = '0 : '+h
        }       
          if(h > 270){
            var total = h - 30
             hour = this.timeConvert(total);
          }     
            return hour;       
      },   
  handleSelectionChange(val) {
        this.multipleSelection = val;
        let selectValue = [];
        this.multipleSelection.map((selectedRow)=>{
          selectValue.push(selectedRow.employee_id);
        })
         globalEvents.$emit('selectRow', selectValue);
        
      },
     beforeMount() {
    this.timer = setInterval(this.fetchData(1), 1000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },

  getEmployee(){
    this.fetchData(1);
  },
    fetchData(p) {
     
      this.loading = true;
      let params = { page: p };
    //   if (this.filter) {
    //     params.filter = this.filter;
    //   }
    //   if (this.sort) {
    //     params.sort = this.sort;
    //   }
      if (this.pagination.per_page) {
        params.per_page = this.pagination.per_page;
      }

      if(this.employee_id){
        params.employee_id = this.employee_id
      }
     
      if(this.date_range){
        for(var i=0;i<this.date_range.length;i++){
           params.start_date = this.date_range[0];
           params.end_date = this.date_range[1];
        }
      }
     
      getEmployeeAllDetails(params).then((response) => { 
       
        this.list = response.data.data;    
              
        this.pagination.current_page = response.data.data.current_page;
        this.pagination.last_page = response.data.data.last_page;
        this.pagination.total_rows = response.data.data.total;
        this.loading = false;
        this.$set(this.list, "_showDetails", true);
      });
      

    },
    downloadTimeSheet(){
       this.loading=true;
       let url = 'pdf/download/employeeTimeSheet'
       this.form.post(url).then((response) => {
          //console.log(response.data);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'employeeTimeSheet.pdf');
          document.body.appendChild(link);
          link.click();
          this.loading = false;
        }).catch(err => {
          console.log(err);
          this.loading = false;
        });
    },
    getEmployeeList(){
      this.loading = true;
      getEmployeeList().then((response) => {       
          this.loading = false;
          this.employee_list = response.data.data;
          
        });
    },
    viewEmployeeSheet(employee_id){
        this.$router.push({name:"employee_time_sheet_details", params: {employeeId: employee_id,date_ranges:this.date_range}})
    }
  }
}
</script>

<style scoped>

.el-form-item__label{
    font-size: 20px !important;
}
.date_field{
    width: 145px;
}
.label_field{
    font-size: 11px;
    text-align: left;
    color: #000000;
    font-weight: 600;
    margin-bottom: 5px;
}
.field_input{
    -webkit-appearance: none;
    background-color: #F8F8F8;
    background-image: none;
    border-radius: 3px;
    border: 1px solid #d4ccdc;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #333;
    display: inline-block;
    font-size: 11px;
    height: 26px !important;
    line-height: 30px;
    outline: 0;
    padding: 0 8px;
    -webkit-transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    width: 100%;   
    -webkit-box-shadow: 0 0 1px 1px inset #f2f1f9;
    box-shadow: 0 0 1px 1px inset #f2f1f9;
   
}
.el-form .el-input__inner{
    font-size: 10px !important;
    display: flex !important;
}
.el-date-editor .el-range-separator{
  width: 0% !important;
}

#product th{
    font-size: 11px !important;
    padding-left: 10px;
}
#product td{
    font-size: 11px !important;
    padding-left: 10px;
    height: 22px;
    overflow: auto;
}
 #product tr:first-child {
    background-color:#EDEDED;
    border:1px solid #EDEDED;
}
.product_table_hd{
     background-color: #EDEDED !important;
     border:1px solid #EDEDED;
}

#product tr:nth-child(odd) {background-color: #EFF8FC;}
.el-input__suffix{
    margin-top: 5px !important;
}
.el-select .el-input .el-select__caret{
    margin-top: 7px !important;
}

.select_field {
   -webkit-appearance: none;
    background-color: #F8F8F8;
    background-image: none;
    border-radius: 3px;
    border: 1px solid #d4ccdc;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #333;
    display: inline-block;
    font-size: 11px;
    height: 26px !important;
    line-height: 30px;
    outline: 0;
    padding: 0 8px;
    -webkit-transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    width: 100%;   
    -webkit-box-shadow: 0 0 1px 1px inset #f2f1f9;
    box-shadow: 0 0 1px 1px inset #f2f1f9;
}
.el-form .el-form-item .el-form-item__label{
  font-size: 11px !important;
}
.el-select{
  widows: 175px !important;
}
.search_button{
  padding: 6px;
    color: white;
    margin-top: 23px;
    padding-left: 10px;
    padding-right: 10px;
}
</style>

